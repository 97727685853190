<!-- Navbar -->
<app-navbar-style-three></app-navbar-style-three>
<!-- End Navbar -->

<!-- Page Title -->
<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="title-item">
                    <h2>Find Jobs</h2>
                    <ul>
                        <li>
                            <img src="assets/png/pinklogo.png" alt="Image">
                            <!-- <a routerLink="/">Home</a> -->
                        </li>
                        <!-- <li><span>/</span></li> -->
                        <li>Find Jobs</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page Title -->

<!-- Jobs -->
<div class="job-filter-area pt-100">
    <div class="container">
        <form>
            <div class="row">
                <div class="col-sm-3 col-lg-2">
                    <div class="form-group">
                        <select class="form-control form-select" (change)="onchangeJobRole($event.target.value)">
                            <option value="" disabled selected>Choose Job Role</option>
                            <option *ngFor="let JobRole of (allJobRole | async)" [value]="JobRole.Id">
                                {{JobRole.JobRole}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-2">
                    <div class="form-group">
                        <select class="form-control form-select" (change)="onchangeCity($event.target.value)">
                            <option value="" disabled selected>Choose City</option>
                            <option *ngFor="let cities of (allCities | async)" [value]="cities.Id">
                                {{cities.CityName}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-2">
                    <div class="form-group">
                        <select class="form-control form-select" (change)="onchangeQualification($event.target.value)">
                            <option value="" disabled selected>Choose Education</option>
                            <option *ngFor="let Quli of (allQualification | async)" [value]="Quli.Id">
                                {{Quli.Qualification}}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="col-sm-3 col-lg-2">
                    <button type="submit" class="cmn-btn" (click)="applyselection()">Filter<i
                            class='bx bx-search'></i></button>
                </div>

                <div class="col-sm-3 col-lg-2">
                    <button type="submit" class="cmn-btn" (click)="removeselection()">Show All<i
                            class='bx bx-search'></i></button>
                </div>

                <div style="margin-left: 529px;">
                    <ngb-pagination [collectionSize]="totalItems" [(page)]="page" [boundaryLinks]="true"
                        (pageChange)="pageChanged(page)" [rotate]="false" [ellipses]="true" [maxSize]="5">
                    </ngb-pagination>
                </div>
            </div>
        </form>
    </div>
</div>

<div class="candidate-area pb-100" style="margin-top: 31px;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="employer-item" *ngFor="let jobpost of obs" (click)="JobClick(jobpost)">
                    <img [src]="jobpost.Logo" alt="Dashboard" style="height: 10%;margin-top: 112px;
                            margin-left: -12px;" *ngIf="jobpost.Logo">
                    <h3>{{jobpost.JobRole}}</h3>
                    <ul>
                        <li><i class="flaticon-send"></i> {{jobpost.CompanyIndustry}}</li>
                        <li>{{jobpost.JobOpening}} Opening</li>
                    </ul>

                    <p>{{jobpost.JobDescription}}</p>

                    <div style="display:flex;flex-flow:initial;column-gap:23px;">

                        <span *ngIf="jobpost.NoticePerioddays" class="span-one five">Joining Period: {{jobpost.NoticePerioddays}}</span>

                        <span class="span-one five">City: {{jobpost.JobLocationCity}}</span>

                        <span class="span-one five">Yrs. of Experience: {{jobpost.MinYearExp}}</span>

                        <span class="span-one five">Qualification: {{jobpost.Qualification}}</span>

                        <span class="span-two five">Salary Range: {{jobpost.MinSalary}}Rs.-{{jobpost.MaxSalary}}Rs</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Jobs -->

<!-- Footer -->
<app-footer-style-two></app-footer-style-two>
<!-- End Footer -->