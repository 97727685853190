import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared.service';

@Component({
  selector: 'app-keshavdashboard',
  templateUrl: './keshavdashboard.component.html',
  styleUrls: ['./keshavdashboard.component.scss']
})
export class KeshavDashboardComponent implements OnInit {

  constructor( private service: SharedService<any>) { }
  info : any;

  ngOnInit(): void {
    this.refreshList();
  }

  refreshList() {
    this.service.Get('Dashboard/GetDashboardData', null).subscribe(data => {
      this.info = data;
   })
  }
}
